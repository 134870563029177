enum OdsDataType {
  USER = 'USER',
  CHANNEL = 'CHANNEL',
  PERMISSION = 'PERMISSION',
  MESSAGE = 'MESSAGE',
  CONTENT = 'CONTENT',
  CONNECTOR = 'CONNECTOR',
  ATOM = 'ATOM',
  GRAPH_DATA = 'GRAPH_DATA',
  TENANT = 'TENANT',
  FILE = 'FILE',
  GROUP = 'GROUP',
  MEETING = 'MEETING',
  ANOMALY = 'ANOMALY',
  ISSUE = 'ISSUE',
  BRANCH = 'BRANCH',
  MAIL = 'MAIL',
  ORGANIZATION = 'ORGANIZATION',
  MESSAGE_THREAD = 'MESSAGE_THREAD',
  SALESFORCE_METADATA = 'SALESFORCE_METADATA',
}

export default OdsDataType;
