import {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';

import Api from 'global/api/platformApi';
import App, {
  AppsWithoutScanners, ParentApp, parentAppsToSubApps, SubApp,
} from 'global/lists/apps';
import Tenant from 'model/Tenant';
import { BackOfficeScanRunningStatus } from 'screens/backoffice/BackofficeScreensConfig';
import OdsDataType from 'screens/backoffice/screens/ods/OdsDataType';
import DebuggerConsole from 'utils/DebuggerConsole';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

const SUGGESTED_TAGS_KEY = 'Suggested tags';

export const appsToScanDataTypes: Record<Exclude<App, App.AKOODA | SubApp | AppsWithoutScanners>,
  Record<string, OdsDataType>> = {
    [App.SLACK]: {
      Users: OdsDataType.USER,
      Channels: OdsDataType.CHANNEL,
      Messages: OdsDataType.MESSAGE,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.GOOGLE]: {
      Users: OdsDataType.USER,
      Groups: OdsDataType.GROUP,
      Meetings: OdsDataType.MEETING,
      Files: OdsDataType.FILE,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.DROPBOX]: {
      Users: OdsDataType.USER,
      Files: OdsDataType.FILE,
    },
    [App.ATLASSIAN]: {
      Users: OdsDataType.USER,
      Projects: OdsDataType.CHANNEL,
      Tickets: OdsDataType.ISSUE,
      Pages: OdsDataType.FILE,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.GITHUB]: {
      Users: OdsDataType.USER,
      Repositories: OdsDataType.CHANNEL,
      Branches: OdsDataType.BRANCH,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.MONDAY]: {
      Users: OdsDataType.USER,
      Workspaces: OdsDataType.CHANNEL,
      Groups: OdsDataType.GROUP,
      Items: OdsDataType.ISSUE,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.FIGMA]: {
      Projects: OdsDataType.CHANNEL,
      Files: OdsDataType.FILE,
    },
    [App.NOTION]: {
      Users: OdsDataType.USER,
      Databases: OdsDataType.CHANNEL,
      Pages: OdsDataType.FILE,
      [SUGGESTED_TAGS_KEY]: OdsDataType.CONTENT,
    },
    [App.GOOGLE_GMAIL]: {
      Mail: OdsDataType.MAIL,
    },
    [App.INTERCOM]: {
      Users: OdsDataType.USER,
      'Message Threads': OdsDataType.MESSAGE_THREAD,
    },
    [App.PHABRICATOR]: {
      Users: OdsDataType.USER,
      Groups: OdsDataType.GROUP,
      Issues: OdsDataType.ISSUE,
      Branches: OdsDataType.BRANCH,
    },
    [App.BITBUCKET]: {
      Users: OdsDataType.USER,
      Repositories: OdsDataType.CHANNEL,
      Branches: OdsDataType.BRANCH,
    },
    [App.SALESFORCE]: {
      Users: OdsDataType.USER,
      Organizations: OdsDataType.ORGANIZATION,
      'Notes/Tasks/Cases': OdsDataType.FILE,
      Feed: OdsDataType.ISSUE,
      Mails: OdsDataType.MAIL,
      Channels: OdsDataType.CHANNEL,
      Meetings: OdsDataType.MEETING,
      Metadata: OdsDataType.SALESFORCE_METADATA,
    },
    [App.ZENDESK]: {
      Users: OdsDataType.USER,
      Channels: OdsDataType.CHANNEL,
      Organizations: OdsDataType.ORGANIZATION,
      Tickets: OdsDataType.ISSUE,
    },
    [App.BOX]: {
      Users: OdsDataType.USER,
      Groups: OdsDataType.GROUP,
      Files: OdsDataType.FILE,
    },
    [App.M365]: {
      Users: OdsDataType.USER,
      Channels: OdsDataType.CHANNEL,
      Groups: OdsDataType.GROUP,
      Files: OdsDataType.FILE,
    },
    [App.ASANA]: {
      Users: OdsDataType.USER,
      Groups: OdsDataType.GROUP,
      Projects: OdsDataType.CHANNEL,
      Tasks: OdsDataType.ISSUE,
    },
    [App.AIRTABLE]: {
      Tables: OdsDataType.CHANNEL,
      Records: OdsDataType.FILE,
    },
    [App.M365_EXCHANGE]: {
      Mail: OdsDataType.MAIL,
    },
  };

const defaultAllTenantsLabel = 'All Tenants';

export default class OdsUtils {
  static getAppsToDataTypes(config: HierarchicalGroup): Record<App, OdsDataType[]> {
    return config.getChildrenEntries()
      .reduce((acc, [appId, appGroup]) => {
        const selectedDataTypesLabels = (appGroup.isParent() ? appGroup.getChildren() : [])
          .filter((child) => child.isSelected);

        const selectedDataTypes: OdsDataType[] = selectedDataTypesLabels
          .map((dataTypeItem) => appsToScanDataTypes[appId][dataTypeItem.id]);
        return {
          ...acc,
          [appId]: selectedDataTypes,
        };
      }, {} as Record<App, OdsDataType[]>);
  }

  static parseAppsUnderSingleConnector(tenantInstalledConnectors: App[]): App[] {
    function getParentApp(appId: App): ParentApp | null {
      const parentAppsToSubAppsEntries = Object.entries(parentAppsToSubApps);
      const parentApps = parentAppsToSubAppsEntries
        .filter(([_, childApps]) => {
          const childAppsAsStrings = childApps.map((childApp) => childApp.toString());
          return childAppsAsStrings.includes(appId.toString());
        })
        .map(([parentApp, _]) => parentApp);

      if (parentApps.length === 0) return null;
      const parentApp = parentApps[0];
      return Number(parentApp);
    }

    const connectedApps = tenantInstalledConnectors.reduce((acc, appId) => {
      acc.add(appId);
      const parentApp = getParentApp(appId);
      if (parentApp) acc.add(parentApp);
      return acc;
    }, new Set<App>());

    return [...connectedApps];
  }
}

export function useAppsScanConfig(
  selectedTenant: Tenant | null,
  setRunningStatus: Dispatch<SetStateAction<BackOfficeScanRunningStatus | null>>,
): HierarchicalGroup | null {
  const [appsScansConfig, setAppsScansConfig] = useState<HierarchicalGroup | null>(null);
  // Used to eliminate race conditions while changing tenants
  const lastSelectedTenant = useRef<null | Tenant>(null);

  useEffect(() => {
    lastSelectedTenant.current = selectedTenant;
  }, [selectedTenant]);

  useEffect(() => {
    async function fetchConnectors() {
      let installedConnectors = Object.keys(appsToScanDataTypes) as unknown[] as App[];
      if (selectedTenant) {
        try {
          const response = await Api.Tenant.getAppsIds(selectedTenant.id);
          if (response?.data) {
            const tenantInstalledConnectors = Object.keys(response.data).map(Number);
            installedConnectors = OdsUtils.parseAppsUnderSingleConnector(
              tenantInstalledConnectors,
            );
            setRunningStatus(null);
          }
        } catch (err) {
          DebuggerConsole.error('Error fetching active connectors');
          setRunningStatus(BackOfficeScanRunningStatus.FAILURE);
          installedConnectors = [];
        }
      }

      if (selectedTenant?.id === lastSelectedTenant.current?.id) {
        const mainLabel = selectedTenant?.name || defaultAllTenantsLabel;
        const connectorsLabels = installedConnectors
          .reduce((acc, appId) => {
            if (appsToScanDataTypes[appId]) {
              return {
                ...acc,
                [appId]: Object.keys(appsToScanDataTypes[appId]),
              };
            }
            return acc;
          }, {} as Record<App, string[]>);

        const nextAppsScansConfig = new HierarchicalGroup(mainLabel, connectorsLabels, true);
        disableSuggestedTags(nextAppsScansConfig);
        setAppsScansConfig(nextAppsScansConfig);
      }
    }

    fetchConnectors();
  }, [selectedTenant]);

  return appsScansConfig;
}

function disableSuggestedTags(nextAppsScansConfig: HierarchicalGroup) {
  nextAppsScansConfig.getChildren().forEach((appDataTypes) => {
    if (appDataTypes.isParent()) {
      appDataTypes.getChildrenEntries().forEach(([key, child]) => {
        if (key === SUGGESTED_TAGS_KEY) {
          child.deselect();
        }
      });
    }
  });
}
